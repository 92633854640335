.instruments-detail {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__horizontal-tabs-container {
    margin-top: 16px;
  }

  &__mobile-track-list {
    margin-top: 8px;
  }
}
