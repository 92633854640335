.file-uploader {
  &--container {
    --background-color: var(--color-cod-gray-300);
    --button-text-color: var(--color-cod-gray);
    --button-color: var(--color-gin);
    height: 9.6rem;
    width: 100%;
    background-color: var(--background-color);
    border: .1rem dashed var(--color-cod-gray-400);
    justify-content: center;
    display: flex;
    margin-bottom: 1rem;
    transition: all .5s;
    outline: none;

    @media (hover: hover) {
      &:hover {
        --background-color: var(--color-cod-gray-500);
        --button-color: var(--color-alabaster);
        --button-text-color: var(--color-cod-gray-500);
      }
    }

    &--has-error {
      border: .1rem dashed var(--color-burnt-sienna);
    }
  }

  &--active-drop {
    border: .1rem dashed var(--color-main-dynamic);
    background-color: var(--color-mine-shaft);
  }

  &--uploader-button {
    height: 3.2rem;
    align-self: center;
    justify-content: space-between;
    background-color: var(--button-color);
    display: flex;
    flex-direction: row;
    padding: 1rem;
    outline-width: 0;
    max-width: 80%;
  }

  &--uploader-button-icon {
    align-self: center;

    > path {
      fill: var(--button-text-color);
    }
  }

  &--uploader-button-icon-check {
    align-self: center;
    flex-shrink: 0;

    > path {
      stroke: var(--color-fruit-salad);
    }
  }

  &--uploader-button-text {
    align-self: center;
    font: 500 1.2rem var(--font-gerstner);
    color: var(--button-text-color);
    padding: .4rem 0 .4rem 1rem;
  }

  &--selected-file-text {
    align-self: center;
    font: 500 1.2rem var(--font-gerstner);
    color: var(--color-fruit-salad);
    white-space: nowrap;
    overflow: hidden;
    margin-left: .5rem;
  }

  &--hint-text {
    align-self: center;
    font: 500 1.2rem var(--font-gerstner);
    color: var(--color-tundora);
  }

  &--hint-text-indent {
    align-self: center;
    font: 500 1.2rem var(--font-gerstner);
    color: var(--color-tundora);
    margin-left: 1rem;
  }
}
