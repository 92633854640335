.divider {
  width: 100%;
  height: 1px;
  background: var(--color-cod-gray-500);
  margin: 1.8rem 0 1.6rem;

  &__large {
    margin: 3.2rem 0;
  }
}
