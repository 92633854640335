.similar-sound-detail {
  padding: 16px;

  @media (--from-tablet-landscape) {
    padding: 16px 64px 16px 0;
  }

  &__title {
    color: var(--color-gin);
    display: flex;
    font: 2.4rem/3.2rem var(--font-gerstner);
    justify-content: space-between;
    letter-spacing: .15px;
    margin-bottom: 42px;
  }

  &__loading {
    margin-top: 16px;

    &-item {
      height: 59px;
      min-width: 100%;
      padding: 0;
      width: 100%;

      @media (--from-tablet-landscape) {
        height: 59px;
        min-width: 100%;
        width: 100%;
      }
    }
  }
}
