.player-wave {
  width: 100%;

  &__wrapper {
    cursor: pointer;
    padding-bottom: 9.677%;/* (31:3 aspect ratio or 1302×126px to ensure even wave sizing) */
    position: relative;
    touch-action: none;
    width: 100%;
  }

  &__duration {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  &__elapsed-wrapper {
    bottom: 0;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 0;
  }

  &__premium-overlay {
    background-color: rgb(var(--color-black-russian-rgb) / 50%);
    bottom: 0;
    height: 100%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
  }

  &__elapsed {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
  }
}
