.mini-player {
  display: flex;
  height: 100%;
  padding: 16px 16px 44px;
  position: relative;
  touch-action: none;
  width: 100%;
  justify-content: center;

  &__song-title-wrapper {
    display: flex;
  }

  @media (--from-tablet-landscape) {
    align-items: center;
    padding: 0;
    padding-left: 16px;
  }

  &__container {
    &--artist {
      align-items: center;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &--create,
    &--supreme {
      align-items: center;
      display: flex;
      width: 100%;
      max-width: 1280px;
      justify-content: space-between;
    }

    @media (--from-tablet-landscape) {
      &--artist {
        display: flex;
        justify-content: space-between;
      }

      &--supreme,
      &--create {
        display: grid;
      }

      &--supreme {
        grid-column-gap: 16px;
        grid-template-columns: 400px 88px 1fr;
      }

      &--create {
        grid-column-gap: 38px;
        grid-template-columns: 300px 1fr 82px;
      }
    }

    @media (--tablet-landscape) {
      &--supreme {
        grid-template-columns: 300px 88px 1fr;
      }
    }
  }

  &__right-section {
    align-items: center;
    display: flex;
  }

  &__details {
    align-items: center;
    display: flex;
    flex: 1 auto;
    min-width: 0;

    @media (--from-tablet-landscape) {
      flex: 0 0 auto;
    }
  }

  &__details-cover {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-right: 8px;
    position: relative;
    width: 40px;
    object-fit: cover;

    @media (--from-tablet-landscape) {
      height: 61px;
      width: 61px;
    }

    &--clickable {
      cursor: pointer;
    }
  }

  &__details-demos-wrapper {
    display: flex;
  }

  &__details-title {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 14rem;
  }

  &__details-title-create {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 23rem;
  }

  &__details-title-wrapper {
    color: var(--color-gin);
    flex-grow: 1;
    font: 500 1.2rem/2rem var(--font-gerstner);
    letter-spacing: .0208em;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 85%;

    @media (--from-tablet-landscape) {
      flex-grow: 0;
      font-size: 1.4rem;
      line-height: 2.4rem;
    }

    &--has-right-fade-mobile::after {
      background: linear-gradient(180deg, var(--color-cod-gray) 22.23%, rgb(18 18 18 / 0%) 104.1%);
      bottom: 0;
      content: '';
      height: 100%;
      position: absolute;
      right: -6px;
      top: 0;
      transform: rotate(90deg);
      width: 51px;

      @media (--from-tablet-landscape) {
        display: none;
      }
    }
  }

  &__details-artist {
    color: var(--color-gin);
    display: block;
    font: 300 1.2rem/2rem var(--font-gerstner);
    letter-spacing: .0333em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 85%;

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      line-height: 1.6rem;
      margin-bottom: 12px;
    }
  }

  &__details-label {
    color: var(--color-gin);
    display: block;
    font: 300 1.2rem/2rem var(--font-gerstner);
    letter-spacing: .0333em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-hover-dynamic);
        cursor: pointer;
      }
    }
  }

  &__demos-dropdown {
    justify-content: flex-start;
  }

  &__version-dropdown {
    @media (--tablet) {
      display: none;
    }
  }

  &__middle-section-create {
    align-items: center;
    display: flex;
    gap: 16px;
  }

  &__controls {
    flex: 0 58px;
    text-align: right;

    @media (--from-tablet-landscape) {
      align-items: center;
      display: flex;
      flex: 0 auto;
      justify-content: center;
      text-align: center;
    }
  }

  &__controls--artist {
    flex: 0 58px;
    text-align: right;
    justify-self: flex-end;

    @media (--from-tablet-landscape) {
      align-items: center;
      display: flex;
      flex: 0 auto;
      justify-content: center;
      text-align: center;
    }
  }

  &__control {
    path {
      transition: fill .25s;
    }

    @media (hover: hover) {
      &:not([disabled]):hover path {
        fill: var(--color-gin);
      }
    }

    &:not(:last-of-type) {
      @media (--from-tablet-landscape) {
        margin-right: 8px;
      }
    }

    &--pause path {
      fill: var(--color-gin);
    }

    &--next-artist,
    &--next,
    &--prev {
      display: none;

      &:disabled {
        opacity: .3;
      }

      @media (--from-tablet-landscape) {
        display: block;
      }
    }

    &--next-artist {
      margin-right: var(--left-padding);
    }

    svg {
      height: 28px;
      width: 28px;
    }
  }

  &__wave {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    transform: translateY(50%);
    width: 100%;
    z-index: 1;

    @media (--from-tablet-landscape) {
      align-items: center;
      display: flex;
      flex: 0 360px;
      margin-right: 16px;
      position: static;
      transform: none;
      width: 360px;

      &--create {
        flex: 1 1 auto;
        max-width: 620px;
        width: 100%;
      }
    }
  }

  &__elapsed,
  &__duration {
    color: var(--color-gin);
    display: none;
    font: 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;
    width: 50px;

    @media (--from-tablet-landscape) {
      display: block;
    }
  }

  &__elapsed {
    color: var(--color-gray);
    margin-right: 16px;
  }

  &__duration {
    margin-left: 16px;
  }

  &__actions {
    display: none;
    padding-right: 16px;

    @media (--from-tablet-landscape) {
      align-items: center;
      display: flex;
      flex: 1 1 auto;
      justify-self: flex-end;
    }
  }

  &__volume-action,
  &__action {
    align-items: center;
    display: flex;
    margin-right: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    path {
      transition: fill .25s;
    }

    @media (hover: hover) {
      &:not([disabled]):hover path {
        fill: var(--color-gin);
      }
    }

    &--active {
      path {
        fill: var(--color-gin);
      }
    }

    &--repeat {
      margin-right: auto;
    }

    &--maxi-player {
      margin-right: 0;

      @media (hover: hover) {
        &:not([disabled]):hover path {
          fill: none;
          stroke: var(--color-gin);
        }
      }
    }
  }

  &__volume-action {
    height: 100%;
    margin: 0 4px;
    position: relative;

    & > button:focus + &--slider-container,
    &:hover &--slider-container {
      display: flex;
    }

    &--volume {
      align-items: center;
      display: flex;
    }

    &--slider-container {
      background: var(--color-cod-gray);
      bottom: 24px;
      display: none;
      justify-content: center;
      left: 0;
      padding-bottom: 10px;
      position: absolute;
      width: 24px;
    }

    &--slider {
      background: var(--color-cod-gray);
      height: 100px;
    }
  }

  &__menu-wrapper {
    margin: 0 4px;
    position: relative;
  }

  &__demos-button {
    align-items: center;
    color: var(--color-gray);
    display: flex;
    padding: 0 5px;
  }

  &__menu-button {
    align-items: center;
    display: flex;
  }

  &__menu {
    background: var(--color-cod-gray-400);
    bottom: calc(100% + 10px);
    position: absolute;
    right: -20px;

    &::after {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid var(--color-cod-gray-400);
      bottom: -7px;
      content: '';
      height: 0;
      position: absolute;
      right: 30%;
      transform: scale(1, 1.6);
      width: 0;
    }

    @media (--from-tablet-landscape) {
      display: block;
    }
  }

  &__menu-item {
    align-items: center;
    color: var(--color-gray);
    display: flex;
    font: 500 1.2rem/1.2rem var(--font-gerstner);
    letter-spacing: .0208em;
    padding: 8px 13px 8px 8px;
    white-space: nowrap;

    svg {
      height: 24px;
      margin-right: 8px;
      width: 24px;
    }
  }
}
