.categories-grid {
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    align-items: center;
    display: flex;
    margin-block-start: 16px;
    margin-block-end: 16px;

    > svg {
      height: 24px;
      width: 24px;

      > path {
        stroke: var(--color-main-dynamic);
      }
    }
  }

  &__container {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    margin-block-end: 8px;

    &--narrow {
      @media (--from-tablet-landscape) {
        grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
      }
    }

    &--medium {
      @media (--from-tablet-landscape) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &--wide {
      grid-template-columns: repeat(auto-fill, 1fr);

      @media (--from-tablet-landscape) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &__ghost-loading-element {
    margin-bottom: 0;
  }
}
