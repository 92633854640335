.suggested-packs-sounds {
  margin: 16px 16px 0;

  @media (--from-tablet-landscape) {
    margin: 16px 0 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-right: 23px;

    @media (--from-tablet-landscape) {
      padding-right: 67px;
    }
  }

  &__horizontal-tabs-container {
    margin-top: 16px;
  }

  &__mobile-track-list {
    margin-top: 8px;
  }

  &__track-list {
    @media (--from-tablet-landscape) {
      margin-right: 64px;
    }
  }
}
