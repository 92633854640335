.generic-carousel {
  margin-bottom: 29px;

  @media (--from-tablet-landscape) {
    margin-bottom: 32px;
  }

  &__card-list {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    gap: var(--left-padding);

    /* Hide Scroll */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  &__card-list::-webkit-scrollbar {
    display: none;
  }

  &__title__container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    @media (--from-tablet-landscape) {
      margin-bottom: 24px;
    }
  }

  &__arrow-btn {
    font-size: 0;

    > svg > path {
      stroke: var(--color-main-dynamic);
      transition: stroke .2s;
    }

    &__change-color {
      > svg > path {
        opacity: .5;
      }

      @media (hover: hover) {
        &:hover {
          cursor: default;
        }
      }
    }

    &__default {
      @media (hover: hover) {
        &:hover {
          cursor: pointer;

          > svg > path {
            stroke: var(--color-gin);
          }
        }
      }
    }
  }

  &__prev-btn {
    margin-left: auto;
  }

  &__prev-btn,
  &__next-btn {
    @media (--tablet) {
      display: none;
    }
  }

  &__title {
    color: var(--color-gin);
    font: 500 1.8rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;

    @media (--from-tablet-landscape) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }
}
