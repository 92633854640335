.comment {
  border-bottom: 1px solid var(--color-cod-gray-400);
  column-gap: 16px;
  display: grid;
  grid-template-areas:
    'avatar author'
    'avatar content';
  grid-template-columns: 44px auto;
  padding: 24px 0;
  position: relative;
  row-gap: 16px;

  @media (--from-tablet-landscape) {
    column-gap: 21px;
    grid-template-columns: 32px auto;
    padding: 32px 0;
  }

  &:last-of-type {
    border-bottom: 0;
  }

  &__avatar-wrapper {
    grid-area: avatar;
  }

  &__avatar {
    border-radius: 100%;
    height: 44px;
    width: 44px;

    @media (--from-tablet-landscape) {
      height: 32px;
      width: 32px;
    }
  }

  &__edit {
    align-items: center;
    color: var(--color-gray-200);
    display: flex;
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
    position: absolute;
    right: 0;
    top: 24px;

    @media (--from-tablet-landscape) {
      top: 32px;
    }
  }

  &__author {
    grid-area: author;
    padding-right: 50px;
  }

  &__author-name {
    color: var(--color-gin);
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
  }

  &__author-date {
    color: var(--color-gray-200);
    font: 300 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
  }

  &__content {
    grid-area: content;
  }

  &__content-input {
    background: var(--color-cod-gray-300);
    border: 1px solid var(--color-cod-gray-400);
    color: var(--color-gray-200);
    display: block;
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    grid-area: input;
    height: 100px;
    letter-spacing: .4px;
    margin-bottom: 16px;
    padding: 16px 16px 16px 12px;
    width: 100%;
  }

  &__content-submit {
    background: var(--color-gin);
    color: var(--color-cod-gray);
    display: inline-block;
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .35px;
    margin-right: 16px;
    padding: 8px 12px;

    &:last-of-type {
      margin-right: 0;
    }

    &--secondary {
      background: none;
      color: var(--color-gin);
    }
  }

  &__content-comment {
    color: var(--color-white);
    font: 300 1.1rem/1.4rem var(--font-gerstner);
    letter-spacing: .4px;

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
}
