.clickable-genres {
  position: relative;

  >*:not(:last-child)::after {
    color: inherit;
    content: ' / ';
  }

  &__item {
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
