.search-sounds {
  &__sorting {
    margin-left: auto;
  }

  &__see-more {
    padding-top: 4px;
  }

  &__loading-container {
    margin-inline: 16px;

    @media (--from-tablet-landscape) {
      margin-inline: 0;
    }
  }

  &__loading {
    margin-top: 16px;

    &-item {
      min-width: 100%;
      padding: 0;
      width: 100%;
    }
  }

  &__intro-section {
    display: grid;
    grid-template-columns: 1fr;
  }
}
