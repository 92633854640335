.favorites {
  &__header {
    align-items: center;
    color: var(--color-gin);
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}
