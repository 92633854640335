.free-sound-packs {
  margin: 16px 0;

  &__header {
    display: flex;
    justify-content: space-between;
    margin: 0 16px;

    @media (--from-tablet-landscape) {
      margin: 0 64px 16px 0;
    }
  }
}
