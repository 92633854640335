.instruments {
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--top-padding);
  }

  &__search {
    margin-inline-start: 16px;
    position: relative;
  }

  &__search-input {
    background: none;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10.5 14.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM13.5 13.5l4 4' stroke='%23919191' stroke-width='1.5' stroke-miterlimit='10'/></svg>");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    border: 0;
    border-bottom: 1px solid var(--color-cod-gray-400);
    color: var(--color-gin);
    display: block;
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
    padding: 10px 28px 10px 0;
    transition: background .2s, color .2s;
    width: 100%;

    &:focus {
      background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10.5 14.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM13.5 13.5l4 4' stroke='%23E6EFE9' stroke-width='1.5' stroke-miterlimit='10'/></svg>");
    }

    &[data-focus-visible-added] {
      box-shadow: inset 0 -1px 0 var(--color-gin);
      outline: none;
    }

    &::placeholder {
      color: var(--color-gray-200);
      font: 300 1.4rem/2rem var(--font-gerstner);
    }

    &:focus::placeholder {
      color: var(--color-gin);
    }

    .instruments__search--has-value & {
      background-image: none;
    }
  }

  &__search-clear {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
