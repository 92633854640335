.comments-form {
  column-gap: 16px;
  display: grid;
  grid-template:
    'avatar input' 100px
    'avatar submit' auto / 44px auto;
  margin-bottom: 8px;
  row-gap: 24px;

  @media (--from-tablet-landscape) {
    column-gap: 24px;
    grid-template-columns: 32px auto;
    margin-bottom: 16px;
  }

  &__avatar-wrapper {
    grid-area: avatar;

    > svg > circle {
      display: none;
    }
  }

  &__avatar {
    border-radius: 100%;
    height: 44px;
    width: 44px;

    @media (--from-tablet-landscape) {
      height: 32px;
      width: 32px;
    }
  }

  &__input {
    background: var(--color-cod-gray-300);
    border: 1px solid var(--color-cod-gray-400);
    color: var(--color-gray-200);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    grid-area: input;
    letter-spacing: .4px;
    padding: 16px 16px 16px 12px;
  }

  &__submit-wrapper {
    grid-area: submit;
  }

  &__submit {
    background: var(--color-gin);
    color: var(--color-cod-gray);
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .35px;
    padding: 8px 12px;
  }
}
