.prize-card {
  display: block;
  max-width: 100%;
  min-height: 100%;
  text-decoration: none;
  width: 180px;

  @media (--from-tablet-landscape) {
    background: var(--color-cod-gray-500);
    padding: 16px;
    width: 296px;
  }

  &__image-wrapper {
    margin-bottom: 8px;

    @media (--from-tablet-landscape) {
      margin-bottom: 16px;
    }
  }

  &__title {
    color: var(--color-gin);
    font: 500 1.3rem/2rem var(--font-gerstner);
    letter-spacing: .35px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (--from-tablet-landscape) {
      width: 264px;
      font-size: 1.4rem;
    }
  }

  &__description,
  &__description * {
    /* !important needed to overwrite inline styles from api */
    color: var(--color-gin) !important;
    font: 300 1.1rem/1.6rem var(--font-gerstner) !important;
    letter-spacing: .4px;
    background-color: unset !important;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (--from-tablet-landscape) {
      width: 264px;
      font-size: 1.2rem !important;
    }
  }
}
