.pack-detail {
  &__ghost-banner-spacer {
    height: 10px;
  }

  &--qc {
    border: 1px dotted red;
  }

  &__img-container {
    background-color: var(--color-cod-gray);
    height: 136px;
    position: relative;

    @media (--from-tablet-landscape) {
      height: 296px;
    }
  }

  &__title-container {
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: space-between;

    > h2 {
      color: var(--color-gin);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__subtitle-container {
    color: var(--color-gin);
    margin: 8px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__info {
    color: var(--color-gray-200);
    display: flex;
    font: 1.1rem/1.4rem var(--font-gerstner);
    letter-spacing: .4px;
    margin: 8px 0;

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    > * {
      white-space: pre-wrap;

      &:not(:last-child)::after {
        color: inherit;
        content: ' / ';
      }
    }
  }

  &__description {
    max-width: 592px;
  }

  &__play-btn {
    > svg > path {
      fill: var(--color-main-dynamic);
      transition: fill .2s;
    }

    @media (hover: hover) {
      &:hover > svg > path {
        fill: var(--color-hover-dynamic);
      }
    }
  }

  &__banner-btn {
    display: flex;

    > svg > path {
      transition: fill .2s;
    }

    @media (hover: hover) {
      &:hover > svg > path {
        fill: var(--color-gin);
      }
    }

    &--favorite {
      display: flex;

      > svg > path {
        transition: fill .2s;
      }

      @media (hover: hover) {
        &:hover > svg > path {
          fill: var(--color-gin);
        }
      }
    }
  }

  &__search-container {
    display: none;

    @media (--from-tablet-landscape) {
      display: block;
      margin-left: auto;
      width: 192px;
    }
  }

  &__track-list-controls {
    align-items: center;
    display: flex;
    gap: 8px;

    @media (--from-tablet-landscape) {
      gap: 0;
    }
  }

  &__track-list {
    margin-top: 24px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: var(--half-padding);
  }
}
