.preset {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__tag-view {
    margin: 2rem 0 3rem;
  }

  &__trending {
    margin-top: 32px;
  }
}
