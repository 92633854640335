.my-drive {
  &__grid {
    column-gap: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(0, 170px));
    row-gap: 16px;

    @media (--from-tablet-landscape) {
      grid-template-columns: repeat(auto-fill, 188px);
    }
  }

  &__tracks {
    color: var(--color-gin);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
  }
}
