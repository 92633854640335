.submit-remix {
  padding: 16px;

  @media (--from-tablet-landscape) {
    padding-right: 64px;
  }

  &__img-wrapper {
    aspect-ratio: 1;
    background: var(--color-cod-gray-500);
    height: 100%;
    width: 100%;

    @media (--from-tablet-landscape) {
      margin-bottom: 32px;
    }
  }

  &__img {
    height: auto;
    width: 100%;

    & > img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__intro {
    display: flex;
    flex-direction: column-reverse;

    @media (--from-tablet-landscape) {
      column-gap: 53px;
      flex-direction: row;
    }
  }

  &__summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 64px;

    @media (--from-tablet-landscape) {
      flex: 0 50%;
    }
  }

  &__title {
    color: var(--color-gin);
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    margin-bottom: 16px;

    @media (--from-tablet-landscape) {
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }

  &__details {
    list-style: none;
    margin: 0 0 24px;
    padding: 0;

    @media (--from-tablet-landscape) {
      margin-bottom: 40px;
    }
  }

  &__detail {
    color: var(--color-gin);
    font: 300 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }

    strong {
      display: block;
      font-weight: 500;
    }

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  &__summary-ctas {
    align-items: center;
    column-gap: 16px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    row-gap: 16px;
  }

  &__summary-cta {
    align-items: center;
    color: var(--color-gin);
    column-gap: 4px;
    display: flex;
    flex: 0 0 auto;
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    justify-content: center;
    letter-spacing: .35px;

    &--primary {
      background: var(--color-gin);
      color: var(--color-cod-gray);
      padding: 8px 13.5px;
    }

    &--secondary {
      border: 1px solid var(--color-gin);
      padding: 8px 19.5px;
    }

    &--tertiary {
      justify-content: left;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 16px 24px;

    @media (--from-tablet-landscape) {
      flex: 0 50%;
    }
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    margin: 16px 0 32px;
  }

  &__input {
    background-color: transparent;
    border: 0;
    box-shadow: inset 0 -1px 0 var(--color-cod-gray-400);
    color: var(--color-gin);
    font: 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .35px;
    margin-bottom: 24px;
    padding: 10px 0;
    width: 100%;

    &[data-focus-visible-added] {
      box-shadow: inset 0 -1px 0 var(--color-gin);
      outline: none;
    }

    &::placeholder {
      color: var(--color-gray-200);
    }
  }

  &__link {
    color: var(--color-main-dynamic);
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;
    margin-right: auto;
  }

  &__share {
    margin-left: auto;
  }

  &__modal-content {
    background: var(--color-cod-gray-100);
    max-width: 1200px;
  }

  &__modal-form {
    margin: 24px;
  }

  &__modal-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
  }

  &__entry {
    align-items: center;
    border-bottom: 1px solid var(--color-cod-gray-400);
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
  }

  &__entry-text {
    color: var(--color-gin);
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
  }

  &__modal-title {
    color: var(--color-gin);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
  }

  &__modal-close-icon {
    > svg {
      height: 16px;
      width: 16px;

      > path {
        stroke: var(--color-gin);
      }
    }
  }

  &__modal-body-title {
    color: var(--color-gin);
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    letter-spacing: .15px;
    margin-bottom: 16px;
  }

  &__modal-body-text {
    color: var(--color-gin);
    font: 300 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
  }

  &__modal-container {
    display: flex;
    flex-direction: column-reverse;
    max-height: calc(100vh - 210px);
    overflow-y: auto;

    @media (--from-tablet-landscape) {
      column-gap: 53px;
      flex-direction: row;
    }
  }

  &__upload-row {
    border: 0;
    box-shadow: inset 0 -1px 0 var(--color-cod-gray-400);
    display: flex;
    padding: 8px 0;

    &__title {
      display: flex;
      flex: 1;

      > span {
        color: var(--color-gray-200);
        font: 300 1.4rem/2rem var(--font-gerstner);
        letter-spacing: .35px;
      }
    }

    &__action {
      align-items: center;
      display: flex;
      justify-content: center;

      > span {
        color: var(--color-main-dynamic);
        font: 1.4rem/2rem var(--font-gerstner);
        letter-spacing: .35px;
      }

      > svg > path {
        stroke: var(--color-main-dynamic);
      }
    }
  }
}
