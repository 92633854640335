.expandable-text {
  /* default styles for the text block */
  &__description {
    color: var(--color-gray-200);
    font: 300 1.2rem var(--font-gerstner);
    letter-spacing: .35px;
    margin-top: var(--top-padding);

    @media (--from-tablet-landscape) {
      font: 300 1.1rem var(--font-gerstner);
      letter-spacing: .4px;
    }

    &--collapsed {
      -webkit-box-orient: vertical;
      /* stylelint-disable-next-line value-no-vendor-prefix */
      display: -webkit-box;
      overflow-y: hidden;
      text-overflow: ellipsis;
    }
  }
}
