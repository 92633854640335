/* WIP - Cleanup */
.create-player {
  background: var(--color-cod-gray);
  border-top: 1px solid var(--color-cod-gray-400);
  bottom: 58px;
  height: 100px;
  left: 0;
  position: fixed;
  right: 0;
  transform: translateY(100%);
  transition: transform .2s, left .2s, right .2s, height .2s;
  z-index: 40;

  @media (--from-tablet-landscape) {
    bottom: 0;
    height: 72px;
    left: 0;
    overflow: visible;
  }

  @media (--from-desktop) {
    height: 80px;
  }

  &--maxi-player {
    height: calc(100% - var(--header-height) - 58px);
    overflow-x: hidden;
    overflow-y: auto;

    @media (--from-tablet-landscape) {
      height: calc(100% - var(--header-height));
    }
  }

  &--filters-open {
    @media (--from-tablet-landscape) {
      right: var(--width-filters-open);
    }
  }

  &--open {
    transform: translateY(0);
  }

  &__versions-menu {
    display: flex;
    flex-direction: column;
  }

  &__versions-menu-item {
    align-items: center;
    display: flex;
    padding: 12px 16px;
  }

  &__versions-menu-item-play {
    flex: 0 0 auto;
    margin-right: 22px;
  }

  &__versions-menu-item-title {
    color: var(--color-gray-200);
    flex: 1 1 auto;
    font: 500 1.6rem/2.4rem var(--font-gerstner);
  }

  &__versions-menu-item-duration {
    color: var(--color-mercury);
    flex: 0 0 auto;
    font: 1.4rem/2.4rem var(--font-gerstner);
    margin-right: 5px;
  }

  &__versions-menu-item-button {
    flex: 0 0 auto;
    margin: 0 8px;
  }
}
