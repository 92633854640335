.my-drive-detail {
  &__actions-container {
    gap: var(--half-padding);

    @media (--tablet) {
      align-self: start;
      justify-content: flex-end;
    }
  }

  &__spinner {
    align-items: center;
    background-color: var(--color-cod-gray);
    border-radius: 50%;
    display: flex;
    height: 48px;
    justify-content: center;
    width: 48px;
    animation: var(--spin-animation) 2s linear infinite;
  }

  &__img-container {
    color: var(--color-white);
    height: 136px;
    padding: 2px 8px;
    position: relative;

    @media (--from-tablet-landscape) {
      height: 296px;
      padding: 18px 24px;
    }

    &--black-text {
      color: var(--color-black);
    }

    > span {
      font: 4.8rem/4.8rem var(--font-gerstner);

      @media (--from-tablet-landscape) {
        font-size: 6.4rem;
        line-height: 6.4rem;
      }
    }

    > svg {
      bottom: 0;
      height: 100px;
      position: absolute;
      right: 0;
      width: 100px;
    }
  }

  &__image {
    bottom: 0;
    height: 50%;
    position: absolute;
    right: 0;
    width: 50%;
  }

  &__missing-img-placeholder {
    align-items: center;
    background-color: var(--color-cod-gray-400);
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 0;
    width: 100%;

    > svg {
      height: 24px;
      width: 24px;

      @media (--from-tablet-landscape) {
        height: 40px;
        width: 40px;
      }
    }
  }

  &__title-container {
    align-items: center;
    display: flex;
    gap: 20px;
    justify-content: space-between;

    > h2 {
      color: var(--color-gin);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__info {
    color: var(--color-gray-200);
    font: 1.1rem/1.4rem var(--font-gerstner);
    letter-spacing: .4px;
    margin: 8px 0;
    display: flex;
    align-items: center;

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    &--stats {
      > span:not(:first-child)::before {
        color: inherit;
        content: ' / ';
      }
    }
  }

  &__download-btn {
    display: none;

    > svg {
      transition: fill .2s;
    }

    > svg > path {
      fill: var(--color-main-dynamic);
    }

    @media (hover: hover) {
      &:hover > svg > path {
        fill: var(--color-hover-dynamic);
      }
    }

    @media (--from-tablet-landscape) {
      display: initial;
    }
  }

  &__track-list-heading {
    display: flex;
    justify-content: space-between;

    @media (--from-tablet-landscape) {
      justify-content: flex-start;
    }
  }
}
