:root {
  --user-menu-z-index: 130;
}

body {
  padding-bottom: 59px;
  padding-top: var(--header-height);
  transition: padding-bottom .25s;

  @media (--from-tablet-landscape) {
    padding-bottom: 0;
  }

  .link {
    color: var(--color-gin);
  }

  .relative-link {
    position: relative;
  }

  @media (hover: hover) {
    .relative-link:hover {
      color: var(--color-alabaster);
    }
  }

  &[data-focus-visible-added] {
    background-color: var(--color-black);
    outline: 1px solid var(--color-dodger-blue);
  }

  .underline-link {
    color: var(--color-gin);
    position: relative;
    text-decoration: none;
  }

  @media (hover: hover) {
    .underline-link:hover,
    .underline-link:hover * {
      text-decoration: underline;
    }

    .link:hover {
      color: var(--color-gin);
    }
  }

  .load-more-in-view {
    height: 1px;
  }
}
