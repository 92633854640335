.nav-my-drive-folders-item {
  align-items: center;
  color: var(--color-boulder);
  display: flex;
  font: 1.4rem var(--font-gerstner);
  letter-spacing: .0094em;
  padding: 12px 12px 12px 20px;
  text-decoration: none;
  transition: background .25s;
  width: 100%;

  &--is-dragging--over {
    background-color: transparent !important;
    color: var(--color-gin) !important;

    svg:not(.nav-my-drive-folders-item--chevron) {
      >path {
        fill: var(--color-gin);
      }

      >g path {
        fill: var(--color-gin);
      }
    }

    svg.nav-my-drive-folders-item--chevron {
      stroke: var(--color-gin);
    }
  }

  @media (hover: hover) {
    &:hover {
      &:not(.nav-my-drive-folders-item--active),
      &:not(.nav-my-drive-folders-item--is-dragging) {
        background-color: var(--color-cod-gray-400);
        color: var(--color-boulder);

        .nav__submenu-btn--context-toggle {
          opacity: 1;
        }

        .nav-my-drive-folders-item__add-collaborators-button {
          opacity: 1 !important;
        }

        .nav-my-drive-folders-item--chevron {
          opacity: 1 !important;
        }

        .nav-my-drive-folders-item--three-dots {
          opacity: 1 !important;
        }
      }
    }
  }

  &:active:not(&--active) {
    &:not(.nav-my-drive-folders-item--is-dragging) {
      background-color: var(--color-cod-gray-300);
      color: var(--color-gin) !important;

      svg:not(.nav-my-drive-folders-item--chevron) {
        >path {
          fill: var(--color-gin);
        }

        >g path {
          fill: var(--color-gin);
        }
      }

      svg.nav-my-drive-folders-item--chevron {
        stroke: var(--color-gin);
      }
    }
  }

  &--active {
    background: var(--color-cod-gray-400);
    color: var(--color-gin) !important;
  }

  @media (--from-tablet-landscape) {
    height: var(--menu-item-height);
    padding: 12px 18px 12px 0;
  }

  >span {
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &__button {
    height: 24px;
    margin-inline-end: var(--half-padding);
    text-align: center;
    transition: background .25s;
    justify-content: center;
    width: 30px;
    display: flex;
    flex: 0 0 var(--menu-item-icon-width);

    @media (--from-tablet-landscape) {
      margin: 0;
      margin-left: var(--left-padding);
    }

    &--active {
      > svg > g > path {
        fill: var(--color-gin);
      }
    }
  }

  &__add-collaborators-button {
    opacity: 1;
    transition: opacity .1s linear;

    @media (--from-tablet-landscape) {
      opacity: 0;
    }
  }

  &--chevron {
    opacity: 1;
    transform: rotate(0deg);
    transition: transform .2s ease-in-out, stroke .2s ease-in-out;

    &--mobile {
      transform: rotate(90deg);
    }

    @media (--from-tablet-landscape) {
      opacity: 0;
    }

    &--open {
      transform: rotate(90deg);
      opacity: 1;
    }

    @media (hover: hover) {
      &:hover {
        opacity: 1;
        stroke: var(--color-gin);
      }
    }
  }

  &--three-dots {
    opacity: 1;

    &--open {
      opacity: 1;
    }

    @media (--from-tablet-landscape) {
      opacity: 0;
    }

    @media (hover: hover) {
      &:hover {
        opacity: 1;
      }
    }
  }
}
