.trending-pack {
  &__intro,
  &__sub-intro {
    align-items: center;
    display: flex;
    gap: 32px;
    justify-content: space-between;
  }

  &__intro {
    margin-bottom: 32px;
  }

  &__sub-intro {
    margin-bottom: 16px;
  }

  &__title {
    color: var(--color-gin);
    flex: 1 auto;
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    margin-bottom: 0;

    @media (--from-tablet-landscape) {
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }

  &__category-dropdown {
    flex-basis: 163px;

    @media (--from-tablet-landscape) {
      flex-basis: 192px;
    }
  }

  &__filters {
    flex: 0 auto;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__subtitle {
    color: var(--color-gin);
    display: flex;
    font: 500 1.8rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;

    @media (--from-tablet-landscape) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  &__card-details {
    color: var(--color-gray-200);
    font: 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .04em;

    @media (--from-tablet-landscape) {
      margin-top: 8px;
    }
  }

  &__grid-container {
    margin-top: 32px;
  }
}
