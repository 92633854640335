.label-detail {
  &__banner-container {
    margin: var(--window-padding) var(--window-padding) 0;
  }

  &__track-list {
    margin-top: var(--top-padding);
  }

  &__img-container {
    align-items: center;
    background-color: var(--color-black);
    display: flex;
    height: 136px;
    justify-content: center;
    position: relative;
    border-radius: 50%;

    @media (--from-tablet-landscape) {
      height: 296px;
    }

    img {
      width: 250px;
      max-height: 100%;
    }
  }

  &__see-more {
    color: var(--color-main-dynamic);
    cursor: pointer;
    font: 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .025em;
    padding-top: 4px;
    text-decoration: none;
  }

  &__title-container {
    align-items: center;
    margin-bottom: var(--top-padding);
    display: flex;
    gap: 20px;
    justify-content: space-between;

    > h2 {
      color: var(--color-gin);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__more-dots-btn {
    display: flex;
    flex-shrink: 0;
  }

  &__tag {
    color: var(--color-gray-200);
    font: 1.1rem/1.4rem var(--font-gerstner);
    letter-spacing: .4px;
    margin: 8px 0;

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    @media (hover: hover) {
      &:not(&--active):hover {
        color: var(--color-gin);
      }
    }

    &--active {
      border-color: var(--color-main-dynamic);
      color: var(--color-main-dynamic);
    }
  }

  &__info {
    color: var(--color-gray-200);
    font: 1.1rem/1.4rem var(--font-gerstner);
    letter-spacing: .4px;
    margin: 8px 0;
    white-space: pre-wrap;

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    > *:not(:last-child)::after {
      color: var(--color-gray-200);
      content: ', ';
    }
  }

  &__info-container {
    display: flex;
    gap: calc(var(--left-padding) / 2);
    padding-top: var(--top-padding);
    justify-content: space-between;

    @media (--from-tablet-landscape) {
      justify-content: flex-start;
    }
  }

  &__info-buttons {
    display: flex;
    justify-content: flex-start;
    gap: calc(var(--left-padding) / 2);
  }

  &__expandable-text {
    max-width: 592px;
  }

  &__banner-btn--favorite {
    display: flex;

    > svg > path {
      transition: fill .2s;
    }

    @media (hover: hover) {
      &:hover > svg > path {
        fill: var(--color-gin);
      }
    }
  }
}
