.curated-pack-detail {
  &__img-container {
    background-color: var(--color-cod-gray);
    height: 136px;
    position: relative;

    @media (--from-tablet-landscape) {
      height: 296px;
    }
  }

  &__mobile-actions {
    display: flex;
    margin-bottom: var(--top-padding);
    gap: var(--half-padding);
  }

  &__title-container {
    align-items: flex-start;
    display: flex;
    gap: 20px;
    justify-content: space-between;

    h2,
    h4 {
      color: var(--color-gin);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    h4 {
      margin-top: 8px;
    }
  }

  &__top-tags > span:not(:last-child)::after {
    content: ', ';
  }

  &__more-dots-btn {
    display: flex;
    flex-shrink: 0;
  }

  &__info {
    color: var(--color-gray-200);
    font: 1.1rem/1.4rem var(--font-gerstner);
    letter-spacing: .4px;
    margin: 8px 0 16px;

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      line-height: 1.6rem;
      margin: 8px 0 24px;
    }

    > *:not(:last-child)::after {
      color: inherit;
      content: ' / ';
    }
  }

  &__description {
    color: var(--color-gin);
    font: 1.3rem/2rem var(--font-gerstner);
    letter-spacing: .35px;
    margin-bottom: 16px;
    max-width: 592px;

    @media (--from-tablet-landscape) {
      font: 300 1.2rem/1.6rem var(--font-gerstner);
      letter-spacing: .4px;
      margin-bottom: 8px;
    }

    &--collapsed {
      -webkit-box-orient: vertical;
      /* stylelint-disable-next-line value-no-vendor-prefix */
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow-y: hidden;
      text-overflow: ellipsis;
    }
  }

  &__banner-btn {
    display: flex;

    > svg > path {
      transition: fill .2s;
    }

    @media (hover: hover) {
      &:hover > svg > path {
        fill: var(--color-gin);
      }
    }

    &--favorite {
      display: flex;

      > svg > path {
        transition: fill .2s;
      }

      @media (hover: hover) {
        &:hover > svg > path {
          fill: var(--color-gin);
        }
      }
    }
  }

  &__search-container {
    display: none;

    @media (--from-tablet-landscape) {
      display: block;
      margin-left: auto;
      width: 192px;
    }
  }

  &__track-list-controls {
    align-items: center;
    display: flex;
    gap: 8px;

    @media (--from-tablet-landscape) {
      gap: 0;
    }
  }

  &__track-list {
    margin-top: 24px;
  }
}
