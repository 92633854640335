.submission {
  border-bottom: 1px solid var(--color-cod-gray-400);
  display: flex;
  flex-direction: column;
  padding: 24px 0 0;
  position: relative;

  @media (--from-tablet-landscape) {
    flex-direction: row;
    padding: 32px 0;
  }

  &__container {
    @media (--from-tablet-landscape) {
      column-gap: 21px;
      grid-template-columns: 32px auto;
      width: 33.3%;
    }
  }

  &:last-of-type {
    border-bottom: 0;
  }

  &__edit {
    align-items: center;
    color: var(--color-gray-200);
    display: flex;
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .4px;
  }

  &__author {
    display: flex;
    justify-content: space-between;
  }

  &__author-container {
    display: flex;
  }

  &__author-row {
    align-items: center;
    display: flex;

    > button:first-of-type {
      margin-right: 4px;
    }

    > span {
      color: var(--color-create);
      font: 500 1.2rem/1.6rem var(--font-gerstner);
      letter-spacing: .4px;
    }
  }

  &__author-name {
    color: var(--color-gin);
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
    margin-right: 18px;
  }

  &__author-date {
    color: var(--color-gray-200);
    font: 300 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .25px;
  }

  &__content {
    grid-area: content;
  }

  &__content-input {
    background: var(--color-cod-gray-300);
    border: 1px solid var(--color-cod-gray-400);
    color: var(--color-gray-200);
    display: block;
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    height: 100px;
    letter-spacing: .4px;
    margin-bottom: 16px;
    padding: 16px 16px 16px 12px;
    width: 100%;

    &--title {
      height: 50px;
    }
  }

  &__content-submit {
    background: var(--color-gin);
    color: var(--color-cod-gray);
    display: inline-block;
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .35px;
    margin-right: 16px;
    padding: 8px 12px;

    &:last-of-type {
      margin-right: 0;
    }

    &--secondary {
      background: none;
      color: var(--color-gin);
    }
  }

  &__content-description {
    color: var(--color-white);
    font: 300 1.1rem/1.4rem var(--font-gerstner);
    letter-spacing: .4px;
    margin-top: 16px;

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  &__song-container {
    align-items: center;
    display: flex;
    flex: 1;
    margin-top: -3px;

    @media (--from-tablet-landscape) {
      align-items: flex-start;
      margin-left: 24px;
    }
  }

  &__wave-container {
    display: none;

    @media (--from-tablet-landscape) {
      display: block;
      margin-right: 16px;
      width: 308px;
    }
  }

  &__title-container {
    align-items: center;
    color: var(--color-gin);
    display: flex;
    flex: 1;
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .35px;
    padding: 24px 0;

    @media (--from-tablet-landscape) {
      padding: 0;
    }

    > path {
      fill: var(--color-gin);
    }
  }

  &__actions {
    align-items: center;
    display: flex;

    &__like {
      margin-right: 4px;

      &--liked {
        path {
          fill: var(--color-create);
          stroke: var(--color-create);
        }
      }
    }
  }
}
