.contest-detail {
  padding: 16px;

  @media (--from-tablet-landscape) {
    padding-right: 64px;
  }

  &__img-wrapper {
    background: var(--color-cod-gray-500);
    margin-bottom: 40px;
    padding: 16px;
    text-align: center;

    @media (--from-tablet-landscape) {
      margin-bottom: 32px;
    }
  }

  &__img,
  img {
    height: auto;
    max-width: 100%;
    object-fit: cover;
  }

  &__intro {
    @media (--from-tablet-landscape) {
      column-gap: 53px;
      display: flex;
    }
  }

  &__summary {
    @media (--from-tablet-landscape) {
      flex: 0 50%;
    }
  }

  &__title {
    color: var(--color-gin);
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    margin-bottom: 16px;

    @media (--from-tablet-landscape) {
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }

  &__details {
    list-style: none;
    margin: 0 0 24px;
    padding: 0;

    @media (--from-tablet-landscape) {
      margin-bottom: 40px;
    }
  }

  &__detail {
    color: var(--color-gin);
    font: 300 1.1rem/1.4rem var(--font-gerstner);
    letter-spacing: .4px;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }

    strong {
      display: block;
      font-weight: 500;
    }

    @media (--from-tablet-landscape) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  &__summary-ctas {
    align-items: center;
    column-gap: 16px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    row-gap: 16px;
  }

  &__summary-cta {
    align-items: center;
    color: var(--color-gin);
    column-gap: 4px;
    display: flex;
    flex: 0 0 auto;
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    justify-content: center;
    letter-spacing: .35px;

    &--primary {
      background: var(--color-create);
      color: var(--color-cod-gray);
      padding: 8px 13.5px;
    }

    &--secondary {
      border: 1px solid var(--color-create);
      color: var(--color-create);
      padding: 8px 19.5px;
    }

    &--tertiary {
      color: var(--color-create);
      justify-content: left;
    }

    &--text {
      text-decoration: none;
    }

    &--disabled {
      background: var(--color-cod-gray-500);
      color: var(--color-tundora);
      pointer-events: none;
    }

    &--svg {
      > svg > path {
        fill: var(--color-main-dynamic);
      }
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;

    @media (--from-tablet-landscape) {
      flex: 0 50%;
    }
  }

  &__description {
    flex: 1 0 100%;
    margin-bottom: 16px;

    * {
      color: var(--color-gin) !important;
      font: 500 1.6rem/2.4rem var(--font-gerstner);
      letter-spacing: .15px;
    }

    a {
      color: var(--color-main-dynamic) !important;
      text-decoration: underline;
    }

    p {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__link {
    color: var(--color-main-dynamic);
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;
    margin-right: auto;
  }

  &__share {
    margin-left: auto;
  }
}
