.genre-card {
  --background-color: var(--color-cod-gray-300);
  --text-color: var(--color-gin);
  --arrow-opacity: 0;
  --icon-opacity: 1;
  --icon-color: var(--color-main-dynamic);
  background-color: var(--background-color);
  transition: background-color .2s;

  @media (hover: hover) {
    &:hover {
      --background-color: var(--color-main-dynamic);
      --text-color: var(--color-black);
      --arrow-opacity: 1;
      --icon-opacity: 0;
      --icon-color: var(--color-black);
    }
  }

  &__default {
    padding: var(--left-padding);
    display: flex;
    justify-content: space-between;
  }

  &__has-icon {
    padding: var(--left-padding);
    height: 106px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (--from-tablet-landscape) {
      height: 138px;
    }
  }

  &__icon {
    &--main {
      opacity: var(--icon-opacity);
      position: absolute;

      svg path {
        fill: var(--color-main-dynamic);
      }
    }

    &--arrow {
      opacity: var(--arrow-opacity);
      transition: opacity .2s;
      position: absolute;

      svg > g > path {
        fill: var(--color-black);
      }
    }
  }

  &__element {
    color: var(--text-color);
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .0035em;
    text-align: start;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: color .2s;
  }

  &__arrow-icon {
    opacity: var(--arrow-opacity);
    transition: opacity .2s;

    svg > g > path {
      fill: var(--color-black);
    }
  }
}
