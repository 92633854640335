.free-sounds {
  &__header {
    display: flex;
    justify-content: space-between;

    @media (--from-tablet-landscape) {
      margin-right: 64px;
    }
  }

  &__horizontal-tabs-container {
    margin-top: 16px;
  }

  &__mobile-track-list {
    margin-top: 8px;
  }
}
