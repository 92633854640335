.submissions {
  margin-bottom: 32px;
  max-width: 100%;
  width: 608px;

  &__title {
    color: var(--color-gin);
    font: 500 1.8rem/2.4rem var(--font-gerstner);
    letter-spacing: .15px;
    margin-bottom: 24px;

    @media (--from-tablet-landscape) {
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 32px;
    }
  }

  &__form {
    margin-bottom: 33px;

    @media (--from-tablet-landscape) {
      margin-bottom: 48px;
    }
  }

  &__see-more {
    color: var(--color-main-dynamic);
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .35px;
    margin-bottom: 32px;
  }
}
